import clientCard from "@/components/client-card";
import Input from "@/components/input";
import packageCard from "@/components/package-card";
import axios from "@/axios";
import AlertInfo from "@/components/alert-box";
import Loader from "@/components/loader";
import Button from "@/components/button";
import Empty from "@shared/empty";
// import { countries } from "@/plugins/constants";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { getChecksList, getPackagesCheck, getEmailSquences } from "@/modules/checks/services.js";
import modalContent from "@shared/modal-content";
import FormBuilder from "@shared/components/form-builder";
import { cloneDeep } from "lodash";

export default {
    name: "create-case",
    components: {
        clientCard,
        Input,
        packageCard,
        AlertInfo,
        Loader,
        Button,
        Empty,
        VueMultiselect,
        modalContent,
        FormBuilder,
    },
    title: "Create Case",
    data() {
        return {
            clients: [],
            selectedClient: null,
            packages: [],
            loaders: {
                clients: false,
                packages: false,
                case: false,
                modal: false,
                caseExists: false,
            },
            searchKeywordClients: "",
            searchKeywordPackages: "",
            package_id: null,
            caseForm: {
                email: "",
                title: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                phone: "",
                // country: "",
                addon_checks: [],
                email_sequence: null,
            },
            packageChecks: [],
            allChecks: [],
            allChecksFetching: false,
            packageLoading: false,
            // countries: countries.map((cu) => cu.name),
            isCaseCreatingInProgress: false,
            emailSequenceFetching: false,
            allEmailSequences: [],
            tenant_package_id: "",
            temp_case_id: "",
            case_form_data: [],
            show_case_form: false,
        };
    },

    computed: {
        filteredClients() {
            return this.clients.filter((cli) => cli.name.toLowerCase().includes(this.searchKeywordClients.toLowerCase()));
        },
        filteredPackages() {
            return this.packages.filter((pkg) => pkg.package_name.toLowerCase().includes(this.searchKeywordPackages.toLowerCase()));
        },
        selectedPackage() {
            return this.package_id ? this.packages.find((pkg) => pkg.id === this.package_id) : null;
        },
        filteredChecks() {
            let newChecksList = this.allChecks.filter((e) => {
                for (let i of this.packageChecks) {
                    if (i.id === e.id) {
                        return false;
                    }
                }
                return true;
            });
            return newChecksList;
        },
        ifCheckisReference(){
            return this.packageChecks.filter(el => el.category.toLowerCase() === 'reference').length
        }
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        await this.fetchAllClients();
        const { client, packageid } = this.$route.query;
        this.selectedClient = client || null;
        this.package_id = packageid || null;
        if (client) await this.fetchPackages(client);
        if (packageid) {
            await this.fetchAllPackageChecks(this.selectedPackage?.package_id);
            await this.fetchChecksList();
            await this.getAllEmailSequence();
        }
    },
    created() {
        // if (this.package_id) this.fetchAllPackageChecks(this.package_id);
    },
    methods: {
        async fetchAllPackageChecks(package_id) {
            this.packageLoading = true;
            this.packageChecks = [];
            const { checks } = await getPackagesCheck(package_id);
            this.packageChecks = checks;
            this.packageLoading = false;
        },
        async fetchChecksList() {
            this.allChecks = [];
            this.caseForm.addon_checks = [];
            this.allChecksFetching = true;
            const tenant = this.$store.getters.getTenantId;
            this.allChecks = await getChecksList(tenant);
            this.allChecksFetching = false;
        },
        async getAllEmailSequence() {
            this.allEmailSequences = [];
            this.caseForm.email_sequence = null;
            this.emailSequenceFetching = true;
            const tenant = this.$store.getters.getTenantId;
            this.allEmailSequences = await getEmailSquences(tenant);
            this.emailSequenceFetching = false;
        },
        async getSelectedEmailSequences() {
            try {
              const { data } = await axios(
                `/screening-package/${this.tenant_package_id}/default-email-sequence`
              );
              if (data.data && data.data.length) {
                let selectedEmailSequence = data.data.find(el => el.external_id_type === 'candidate')
                if (selectedEmailSequence) {
                    this.caseForm.email_sequence =  this.allEmailSequences.find(el => el.id === selectedEmailSequence.email_sequence_id)
                }
                // data.data.forEach(seq => {
                //     this.selected_sequence[seq.external_id_type] = this.email_option.options.find(ems => ems.id === seq.email_sequence_id) || null 
                // });
              }
            } catch (error) {
              console.log("error :>> ", error);
            }
          },
        async fetchAllClients() {
            try {
                const ten_id = this.$store.getters.getTenantId;
                this.loaders.clients = true;
                this.clients = [];
                const { data } = await axios(`tenant/${ten_id}/client`);
                this.clients = data;
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.loaders.clients = false;
        },
        handleRemoveCheck(check) {
            this.caseForm.addon_checks = this.caseForm.addon_checks?.filter((el) => el.id !== check.id);
        },

        async setClient(client) {
            this.package_id = null;
            this.show_case_form = false;
            this.selectedClient = client.id;
            await this.fetchPackages(client.id);
            this.$router.push({ query: { client: client.id } });
        },

        async fetchPackages(client_id) {
            try {
                this.packages = [];
                this.loaders.packages = true;
                const { data } = await axios(`screening-package/client/${client_id}`);
                this.packages = data;
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.loaders.packages = false;
        },
        cancelCase() {
            this.package_id = null;
            this.caseForm = {};
            this.$router.push({ query: { client: this.selectedClient } });
        },
        async isCaseAlreadyExists() {
            let packages = cloneDeep(this.packages);
            let index = packages.findIndex((pack) => pack.id === this.package_id);
            packages[index].isLoading = true;
            this.packages = packages;
            try {
                const { data } = await axios.post("/case/incomplete-created-case", {
                    package_id: this.package_id,
                    client_id: this.selectedClient,
                });
                packages[index].isLoading = false;
                this.packages = packages;
                return data;
            } catch (error) {
                packages[index].isLoading = false;
                this.packages = packages;
            }
        },
        async caseCreate(tenant_package) {
            this.package_id = tenant_package.id;
            this.tenant_package_id = tenant_package.package_id;
            const isExists = await this.isCaseAlreadyExists();
            if (isExists.case_id) {
                this.temp_case_id = isExists.case_id;
                await this.getCaseFormData(this.temp_case_id);
            } else {
                this.loaders.modal = true;
                this.$router.push({
                    query: { client: this.selectedClient, packageid: tenant_package.id },
                });
                this.$refs["create-case-modal"].showModal();
                if (this.package_id) {
                    await this.fetchAllPackageChecks(this.tenant_package_id);
                    await this.fetchChecksList();
                    await this.getAllEmailSequence();
                    await this.getSelectedEmailSequences()
                }
                this.loaders.modal = false;
            }
        },
        async submitCase(hasErrors) {
            if (hasErrors) {
                this.$toast.error(`Please fill all the required fields before submitting`);
                return;
            }
            // let addons = []
            // if (!this.caseForm.email_sequence) {
            // 	this.$toast.error(`Please select Email Sequence`)
            // 	return
            // }
            let addons_check = this.caseForm.addon_checks.map((el) => {
                return el.id;
            });
            const params = {
                package_id: this.package_id,
                email: this.caseForm.email,
                title: this.caseForm.title,
                first_name: this.caseForm.first_name,
                last_name: this.caseForm.last_name,
                phone: this.caseForm.phone,
                // country: this.caseForm.country,
                middle_name: this.caseForm.middle_name,
                client_id: this.selectedClient,
                addon_checks: addons_check,
            };
            // email_sequence: this.caseForm.email_sequence &&  this.caseForm.email_sequence.id || ''
            if (this.caseForm.email_sequence && this.caseForm.email_sequence.id) {
                params["email_sequence"] = this.caseForm.email_sequence.id;
            }
            try {
                this.isCaseCreatingInProgress = true;
                const response = await axios.post(`/case`, params);
                if (response && response.data) {
                    this.$toast.success(response?.data?.message || "Case Created Successfully!");
                    this.handleRestCase();
                }
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Error! Please try again");
            }
            this.isCaseCreatingInProgress = false;
        },
        handleRestCase() {
            this.$formulate.reset("create_case");
            this.caseForm.addon_checks = [];
            this.caseForm.email_sequence = null;
        },

        async createTempCase() {
            // create temp case
            try {
                let body = {
                    package_id: this.package_id,
                    client_id: this.selectedClient,
                    addon_checks: this.caseForm.addon_checks.map((el) => el.id) || [],
                    candidate_form_link_expiry: parseInt(this.selectedPackage.candidate_form_link_valid_duration),
                    reference_form_link_expiry: parseInt(this.selectedPackage.reference_form_link_valid_duration),
                    additional_form_link_expiry: parseInt(this.selectedPackage.additional_form_link_valid_duration)
                };
                // email_sequence: this.caseForm?.email_sequence?.id || "",
                if (this.caseForm && this.caseForm.email_sequence && this.caseForm.email_sequence.id) {
                    body.email_sequence = this.caseForm.email_sequence.id;
                }
                const { data } = await axios.post(`case/create-temp-case`, body);
                this.temp_case_id = data.case_id;
                // get form data
                await this.getCaseFormData(this.temp_case_id);
            } catch (error) {
                this.$toast.warning(error.response.data.detail || `Something went wrong!`);
            }
        },

        async getCaseFormData(case_id) {
            try {
                this.loaders.modal = true;
                const { data } = await axios(`case/case/${case_id}/case-creation-form-v2`);
                this.case_form_data = data || [];
            } catch (error) {
                console.log("error :>> ", error);
            } finally {
                this.loaders.modal = false;
                this.$refs["create-case-modal"].hideModal();
                this.show_case_form = true;
            }
        },

        /* eslint-disable no-mixed-spaces-and-tabs */
        async saveSection(payload) {
            let sectionSaved = false;
            try {
                await axios.post(`/case/case-creation-form/${this.temp_case_id}`, this.formatSections(payload));
                sectionSaved = true;
            } catch (error) {
                sectionSaved = false;
            }
            return sectionSaved;
        },
        /* eslint-disable no-mixed-spaces-and-tabs */
        formatSections(data) {
            return { sections: [data.data] };
        },
        async submitForm(payload) {
            try {
                const sectionSaved = await this.saveSection(payload);
                if (sectionSaved) {
                    await this.submitCaseForm(this.temp_case_id);
                }
            } catch (error) {
                console.log("error :>> submitForm ", error);
            }
        },
        async submitCaseForm(caseId) {
            const url = `/case/case-creation-form/${caseId}/submit`;
            try {
                // api doesn't return anything
                await axios.post(url);
                // if (response) {
                this.$toast.success("Case created successfully!");
                this.show_case_form = false;
                // }
            } catch (error) {
                this.$toast.error(error.response.data.detail);
            }
        },
        cancelCaseForm() {
            this.show_case_form = false;
            // this.selectedPackage = null;
            this.package_id = null;
            this.$router.push({ query: { client: this.selectedClient } });
        },
        async deleteCase() {
            try {
                await axios.delete(`/case/${this.temp_case_id}`);
                this.cancelCaseForm();
            } catch (error) {
                console.log(error, "[+] Error while deleting case");
            }
        },
    },
};
